<template>
  <div class="choose">
    <!-- <div 
      v-for="item in list" 
      :key="item.label" 
      @click="clickRadio(item.label)" 
      :class="value === item.label ? 'active' : ''">
      <img :src="value === item.label ? item.active : item.src" alt="">
      {{ item.title }}
    </div> -->
    <div 
      v-for="item in list" 
      :key="item.label" @click="clickRadio(item.label)" 
      :class="value === item.label ? 'active' : ''"
    >
    {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    value: {
      type: String
    }
  },
  data () {
    return {
      list: [
        { label: '/push/adaptationproject', title: '适配项目推送' },
        { label: '/push/followpolicy', title: '关注政策推送' },
        { label: '/push/mysubscribe', title: '订阅政策推送' }
      ]
    };
  },
  mounted () {},
  methods: {
    clickRadio (label) {
      this.$emit('input', label);
      this.$emit('change', label);
    }
  }
};
</script>

<style lang="less" scoped>
.choose {
  height: 50px;
  display: flex;
  border-radius: 5px;
  border: 1px solid #016AB6;
  div {
    background-color: #FFF;
    color: #016AB6;
    text-align: center;
    line-height: 50px;
    width: 160px;
    height: 50px;
    box-sizing: border-box;
    transition: all .5s;
    cursor: pointer;
    border-right: 1px solid #016AB6;
    &:last-child{
      border-right: none;
      border-radius: 0 5px 5px 0;
    }
    &:first-child{
      border-radius: 5px 0 0 5px;
    }
    &.active {
      color: #fff;
      background-color: #016AB6;
    }
  }

}

</style>